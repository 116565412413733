.about_box {
    width: 90%;
    height: auto;
    border-radius: 8px;
    margin: 3% 5%;
    word-break: break-all;
    color: #303134;
    font-size: 14px;
}

.about_box img {
    max-width: 390px;
    width: 100%;
    height: auto;
    object-fit: contain;
}

.about_box p {
    margin: 10px 0;
    overflow: hidden
}

.about_box a {
    color: #02b2d6;
    width: 100%;
    display: block;
    white-space: pre-wrap
}